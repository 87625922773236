import React from "react"
import { BackButton } from '../components/Icons'
import { Link } from 'gatsby'

const Page = () => {

    const textStyle = {margin: 'auto', width: '75%', textAlign: 'left'}
    const bodyStyle = {margin: 'auto', width: '75%', textAlign: 'left', overflowY: 'auto', maxHeight: '70%'}

    return(
        <div className="base-layout">
            <div className="title">
                privacy
            </div>
            <div className="medium-text" style = {bodyStyle} >
                We recognize that you may be using this tool to make decisions that are personal and sensitive.
                <br/><br/>
                With this in mind, we do not (and never will) collect any personal information from our users.  The text that you enter describing a decision to take, or options to choose between, will never leave your device - unless you intentionally share content via social media.
                <br/><br/>
                To help improve the service, we do have future plans to track some anonymous data about how users are engaging with the website (although we don't do this yet).
                <br/><br/>
                For more details see out formal <Link to='/privacy-policy'>privacy policy</Link> and <Link to='/data-policy'>data policy</Link>.
                <br/><br/>
                Be aware that data entered by you may be accessible to others who may have access to your phone.
                <br/><br/>
                Closing the browser window or navigating away from this site when you have finished using it will help to ensure that the data that you enter remains private.
                <br/><br/>
                If you have further questions, please join the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord server</a>
            </div>
            <BackButton
                text="back"/>
        </div>
    )
}

export default Page